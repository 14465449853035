<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12 order-md-1 pt-4 privacy-content">
                <h4>Privacy Policy</h4>
                <h5 class="mb-3">Billing Address</h5>
                <p>
                    Falconcity of Wonders L.L.C and our subsidiaries and
                    affiliates ("FCW" or "we") respect your preferences
                    concerning the treatment of Personal Information that we may
                    collect. This policy lets you know how we collect and use
                    your Personal Information, how you can control its use, and
                    describes our practices regarding information collected from
                    the different FCW sites that link or refer to this policy
                    (such as our websites, computer or mobile software
                    applications, social media pages and HTML-formatted e-mail
                    messages), as well as through offline sales and marketing
                    activities (collectively, the "Channels").
                </p>
                <p>
                    The Channels may provide links to or the ability to connect
                    with non-FCW websites, services, social networks or
                    applications. Clicking on those links or enabling those
                    connections may allow the third party to collect or share
                    information about you. Those third-party websites or
                    services are beyond our control. We encourage you to check
                    the privacy policies and terms of use of any non-FCW
                    websites or services before providing your Personal
                    Information to them.
                </p>

                <h5 class="mb-3">Personal and Other Information We Collect</h5>
                <p>
                    Through your interaction with and use of the Channels, FCW
                    may collect Personal Information, which is information that
                    identifies an individual or relates to an identifiable
                    individual. Personal Information may include, but is not
                    limited to, your name, physical address, telephone number,
                    e-mail address, company affiliation and associated
                    interests. FCW may also collect Other Information through
                    your interaction with and use of the Channels and non-FCW
                    websites, which does not reveal your specific identity or
                    does not directly relate to an individual. Other Information
                    may include, but is not limited to, browser and device
                    information, data collected through automated electronic
                    interactions, application usage data, demographic
                    information, geographic or geo-location information,
                    statistical and aggregated information. Statistical or
                    aggregated information does not directly identify a specific
                    person, but it may be derived from Personal Information. For
                    example, we may aggregate Personal Information to calculate
                    the percentage of users in a particular area. In some
                    instances, we may combine Other Information with Personal
                    Information, such as combining a precise geographical
                    location with your name. If we combine Other Information
                    with Personal Information, we will treat the combined
                    information as Personal Information.
                </p>

                <h5 class="mb-3">
                    How We Use and Share Information <br />
                    Personal Information
                </h5>
                <p>
                    FCW uses and shares the Personal Information it collects
                    (unless otherwise restricted by applicable law), to: <br />
                    <strong>Engage in Transactions - </strong>FCW may use
                    Personal Information to engage in transactions with you.
                    <br />
                    <strong>Process Transactions - </strong>FCW may use Personal
                    Information to process your transactions. <br />
                    <strong>Provide Support or Other Services - </strong> FCW
                    may use your Personal Information to provide you support or
                    other services you have ordered and other similar
                    operational communications. <br />
                    <strong
                        >Tailor Marketing to Your Needs and Respond to Your
                        Requests -
                    </strong>
                    FCW may use your Personal Information to notify you about
                    new product releases and service developments, and to
                    advertise FCW's products and services in accordance with
                    this Policy. Your website visit, marketing experience, and
                    communications may be tailored to your interests based on
                    your Personal Information. FCW may also use Personal
                    Information in order to respond directly to your information
                    requests (including newsletter registrations or other
                    specific requests), or pass your contact information to the
                    appropriate FCW distributor or reseller for further
                    follow-up related to your interests. In addition, if you
                    consent, FCW may share your Personal Information with other
                    selected partners that offer complementary products or
                    services. Please review the specific partner's privacy
                    policy regarding any further interactions with them. <br />
                    <strong>Provide FCW Online Communities - </strong> Some
                    Channels permit you to: participate in interactive
                    discussions; create a profile; post comments, opportunities
                    or other content; communicate directly with another user; or
                    otherwise engage in networking activities. Some of these
                    services are moderated and all may be accessed for technical
                    reasons. FCW does not control the content that users post to
                    these forums or social networks. You should carefully
                    consider whether you wish to submit Personal Information to
                    these forums or social networks and whether you wish to make
                    your profile available to other users, and you should tailor
                    any content you submit appropriately. You should also review
                    any additional terms and conditions that may govern your use
                    of these Channels. To manage or remove your Personal
                    Information from our online communities, please fill out a
                    request form. If we are not able to remove your Personal
                    Information, we will let you know. <br />
                </p>
                <p>
                    <strong
                        >Interact with You on Third Party Social Networks -
                    </strong>
                    FCW may use your Personal Information to interact with you
                    on third party social networks. FCW's interactions with you
                    on a third party social network would be subject to that
                    network's privacy policies and terms of use.
                </p>
                <p>
                    <strong>Post Testimonials</strong> We post testimonials on
                    our Channels that may contain Personal Information. Prior to
                    posting a testimonial, we will obtain your consent to use
                    your name and testimonial. If you wish to update or delete
                    your testimonial, please fill out a request form, and be
                    sure to include your name, testimonial location, and contact
                    information.
                </p>
                <p>
                    <strong
                        >Select Content, Improve Quality and Facilitate Use of
                        the Channels -
                    </strong>
                    FCW may use your Personal Information to help create and
                    personalize content on our Channels, facilitate your use of
                    the Channels (for example, to facilitate navigation and the
                    login process, avoid duplicate data entry, enhance security,
                    keep track of shopping cart additions and preserve order
                    information between sessions), improve quality, track
                    marketing campaign responsiveness (including online
                    advertising and e-mail marketing), and evaluate page
                    response rates.
                </p>
                <p>
                    <strong>Provide various FCW Communities - </strong> If you
                    sign up for an FCW community or program, FCW will provide
                    you information regarding your expressed interests, such as
                    new product releases, alerts, updates, prices, terms,
                    special offers and associated campaigns.
                </p>
                <p>
                    <strong>Power Joint Sales or Product Promotions - </strong>
                    FCW and its partners may engage in joint sales or product
                    promotions. Such promotions will always reference the
                    partners involved. Both FCW and the partner(s) will have
                    access to that information, and either FCW or our partners
                    may provide you the sales or product promotion information.
                    Each party will be responsible for managing their own use of
                    the Personal Information collected for the joint sale or
                    product promotion. We recommend you review the privacy
                    policies of these partners to address any questions you have
                    regarding their handling of your information.
                </p>
                <p>
                    <strong>Contact a Referral/"Tell a Friend" - </strong> If
                    you elect to use our referral service to inform a friend
                    about our products or services, we will ask you for the
                    friend's contact information. FCW will then send your friend
                    a one-time contact related to your referral request. FCW
                    uses the Personal Information you provide in this situation
                    solely for the purpose of sending the one-time contact and
                    tracking the success of our referral program. Your friend
                    may contact FCW through our inquiry form to be removed from
                    the referral program.
                </p>
                <p>
                    <strong
                        >Improve Products, Services, and Experiences -
                    </strong>
                    FCW may use your Personal Information to evaluate and
                    improve our products, services, marketing, and customer
                    relationships.
                </p>
                <p>
                    <strong
                        >Communicate with You about a Conference or Event -
                    </strong>
                    We or our partners may communicate with you about a
                    conference or event hosted or co-sponsored by FCW or one of
                    our partners. This may include information about the event's
                    content, logistics, payment, updates, and any additional
                    meetings, special demonstrations or other customer
                    facilitation that we may provide related to your event
                    registration. After the event, FCW may contact you about the
                    event and related products and services, and may share
                    information about your attendance with your company. Where
                    legally permitted, FCW will also allow designated partners
                    or conference sponsors to send you up to two communications
                    related to your event attendance. Please note that our
                    partners or conference sponsors may directly request your
                    Personal Information at their conference booths or
                    presentations. You should review their privacy policies to
                    learn how they use Personal Information.
                </p>
                <p>
                    <strong>Obtain Third Party Services - </strong> We also
                    share Personal Information with third parties who provide
                    services to FCW, such as credit card processing services,
                    order fulfillment, analytics, event / campaign management,
                    web site management, information technology and related
                    infrastructure provision, customer service, e-mail delivery,
                    auditing, and other similar services. When FCW shares
                    Personal Information with third party service providers, we
                    require that they use your Personal Information only for the
                    purpose of providing services to us and subject to terms
                    consistent with this Policy.
                </p>
                <p>
                    <strong
                        >Protect our Employees, Sites, Facilities, and
                        Operations -
                    </strong>
                    FCW may disclose your Personal Information as we believe to
                    be necessary or appropriate to protect the health and safety
                    of our employees and visitors, our physical and online
                    operations, our property, rights, and privacy, and/or that
                    of our affiliates, you or others. If you visit our offices,
                    you may be photographed or videotaped as part of maintaining
                    the security of our facilities. Facility security
                    photography will be treated as sensitive information and
                    will only be used for security and investigation purposes.
                </p>
                <p>
                    <strong
                        >Comply with Legal Requirements and Corporate
                        Transactions -
                    </strong>
                    FCW may disclose your Personal Information as we believe to
                    be necessary or appropriate: (a) under applicable law,
                    including laws outside your country of residence; (b) to
                    comply with legal process; (c) to respond to requests from
                    public and government authorities, including public and
                    government authorities outside your country of residence;
                    (d) to enforce our terms and conditions; and (e) to allow us
                    to pursue available remedies or limit the damages that we
                    may sustain. Additionally, in the event of a reorganization,
                    merger, sale, joint venture, assignment, transfer or other
                    disposition of all or any portion of our business, assets or
                    stock (including in connection with any bankruptcy or
                    similar proceedings), we may transfer the Personal
                    Information we have collected to the relevant third party.
                    Some of the Personal Information uses specified above are
                    not mandatory and may be controlled by you.
                </p>
                <p class="text-danger">
                    All credit/debit cards details and personally identifiable
                    information will NOT be stored, sold, shared, rented or
                    leased to any third parties.
                </p>

                <h5 class="mb-3">Other Information</h5>

                <p>
                    We may use, transfer, and disclose Other Information we
                    collect for any purpose, except where applicable law
                    requires otherwise. If we are required to treat Other
                    Information as Personal Information under applicable law,
                    then we will only use it in the same way that we are
                    permitted to use and disclose Personal Information.
                </p>

                <h5 class="mb-3">Acceptance</h5>

                <p>
                    By using the information and facilities of the Site, signify
                    your agreement to FCW Privacy Policy. You are hereby
                    requested to not use this Site, if you do not agree with
                    this Policy. Your continued use of our Site following
                    posting of changes to these terms will mean you accept those
                    changes.
                </p>
                <p>
                    <strong>Protection & safety of your Information</strong> We
                    take utmost importance & care for the protection of all
                    information associated with our customers received through
                    our Site. We at Falconcity.com therefore exercise the utmost
                    care in providing secure transmission of your information
                    from your computer to our servers. Unfortunately no data
                    transmission over the internet can be guaranteed to be 100%
                    secure. As a result while we strive to protect your
                    information, we can’t ensure or warrant the security of any
                    information you transmit to us or from our online products
                    or services and you do so at your own risk. Once we receive
                    your transmission, we use industry standard efforts to
                    safeguard the confidentiality of your information such as
                    using necessary
                    <span class="text-danger"
                        >Firewalls and Secure socket Layers or TLS.</span
                    >
                    However, perfect security does not exist on the internet.
                </p>
                <p>
                    As a policy, your information with us, whether private or
                    public will not be sold, exchanged, transferred, or given to
                    any other user for any reason whatsoever without your
                    consent, other than for dealing with your transactions or
                    services requested or when we believe in good faith that the
                    law requires it. This exclusion does not include trusted
                    third parties who assist us in operating our Site ,
                    conducting or supporting our business or servicing you in
                    any manner, so long as those parties agrees to keep this
                    information confidential.
                </p>

                <h5 class="mb-3">Third Party Links</h5>

                <p>
                    We, at our discretion, may include third party products or
                    services on our Site. These third party sites have separate
                    independent privacy policies. We therefore have no
                    responsibility or liability for the content and activities
                    of these linked sites. Nevertheless we seek to protect the
                    integrity of our Site under our Policy.
                    <strong>Your control over your information</strong> In case
                    you would like to change or edit some of the personal
                    information, we will try to provide a way to correct, update
                    or remove such information that you may have provided to us.
                    We allow the customers to opt out by un registering from
                    Falconcity.com and our periodic emails, messages or
                    newsletters to you would be accordingly be stopped. If a
                    customer wishes to update personal information or objects to
                    any usage provided herein for any reason he/she may email
                    the requested change to
                    <span class="text-primary">fcwop@almoosa.ae</span> email.
                </p>

                <h5 class="mb-3">Changes in Privacy Policy</h5>

                <p class="text-danger">
                    "The Website Policies and Terms & Conditions may be changed
                    or updated occasionally to meet the requirements and
                    standards. Therefore the Customers' are encouraged to
                    frequently visit these sections in order to be updated about
                    the changes on the website. Modifications will be effective
                    on the day they are posted."
                </p>

                <h5 class="mb-3">Contacting Us</h5>

                <p>
                    For any questions about this Policy, please email to
                    <span class="text-primary">fcwop@almoosa.ae</span> or write
                    to : Office No: 214,Almoosa Business Centre,Um Hurair
                    Road,Oud Metha, P.O Box 120660
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {};
</script>
<style scoped></style>
